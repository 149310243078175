<template>
  <div v-animate-css="{
        classes: 'fadeIn',
        duration: 1000,
      }">
    <div class="text-center mb-5">
      <h4>{{question.order}}. {{ question.title }}</h4>
      <p>{{ question.description }}</p>
    </div>
    <div class="row">
      <div class="col-12 col col-md-8 offset-md-2 order-2">
        <div class="option shadow-sm"   @click="handleAnswerQuestion(option.order)"  :class="{'selected':  result === option.order}" v-for="(option, i) in question.options" :key="i + 1">
          <h5 class="ps-4">{{ option.title }}</h5>
          <div>
            <div class="html" v-html="option.text"></div>
          </div>
        </div>
      </div>
    </div>
    <div class=" buttons row mt-5 ">
      <div class="col-6">
        <button class="btn btn-outline-success w-100 controls-button" @click="handlePrev">
          {{ $store.getters.getPrevText }}
        </button>
      </div>
      <div class="col-6">
        <button class="btn btn-outline-success w-100 controls-button" :disabled="handleValidation" @click="handleNext">
          {{$store.getters.getNextText}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {radioInitialData} from "../../utils/questionsInitialData";
import store from "../../store";
import Vue from 'vue';
import VAnimateCss from 'v-animate-css';

Vue.use(VAnimateCss);

export default {
  name: "Radio",
  props: {},
  data() {
    return {
      question: radioInitialData,
      result: 0,
    }
  },
  mounted() {
  },
  computed: {
    handleValidation() {
      return !this.result
    }
  },
  watch: {
    '$store.state.currentQuestion': function () {
      this.question = store.state.currentQuestion;
      if (store.state.currentQuestion.result.answer !== null) {
        this.result = store.state.currentQuestion.result.answer
      }
    }
  },
  methods: {
    async handleNext() {
      const data = {
        idQuestion: store.getters.getCurrentQuestion._id,
        orderQuestion: store.getters.getCurrentQuestion.order,
        section: 'valorization',
        idUser: JSON.parse(localStorage.userData)._id,
        email: JSON.parse(localStorage.userData).email,
        answer: this.result
      }
      this.$emit('sendQuestion', data);
    },
    handlePrev() {
      this.$emit('goPrev');
    },
    getQuestionData() {
      if (store.state.currentQuestion.type === 'multipleWithImages') {
        this.question = store.state.currentQuestion;
      }
    },
    handleAnswerQuestion(value) {
      this.result = value;
    }
  }
}
</script>

<style scoped lang="scss">

.icon {
  fill: #23AF4D;
}

.controls-button {
  &:hover {
    .icon   {
      fill: white ;
    }
  }
}
.buttons {
  button {
    margin: 0 0.5rem;

    &:disabled {
      filter: grayscale(100%);
      opacity: 0.6;
      pointer-events: none;
    }
  }
}

.html {
  ul {
    list-style: none !important;
  }
  li {
    list-style: none !important;
  }
}

.option {
  padding: 1rem;
  cursor: pointer;
  margin: 0.5rem 0;
  position: relative;
  border: 1px solid transparent;

  &:hover {
    transition: background-color 200ms ease;
    border: 1px solid rgba(35, 175, 77, 1);
    //background: rgba(35, 175, 77, 0.1);
  }

  & h5::before {
    content: '';
    margin-left: -1.3rem;
    margin-right: 1rem;
    display: inline-block;
    width: 10px;
    height: 10px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    border: 1px solid #23af4d;
    background-color: transparent;
  }

  &:hover {
    & h5::before {
      transition: background-color 200ms ease;
      background-color: #23af4d;
    }
  }
}

.selected {
  background-color: rgba(35, 175, 77, 0.1);
  border: 1px solid rgba(35, 175, 77, 1);
  & h5::before {
    content: '';
    //position: absolute;
    //top: 40%;
    display: inline-block;
    width: 10px;
    height: 10px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    border: 1px solid #23af4d;
    background-color: #23af4d;
    margin-left: -1.3rem;
    margin-right: 1rem;
  }
}
</style>
